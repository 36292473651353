<template>
	<div>
		<v-row no-gutters class="py-6 d-sm-flex d-block">
			<v-col cols="12" sm="6" class="d-flex align-center">
				<img v-if="value.category_id === 1" :src="$store.state.icons.icons['Lumber']" style="height: 36px" alt />
				<img v-if="value.category_id === 2" :src="$store.state.icons.icons['Electric']" style="height: 36px" alt />
				<img v-if="value.category_id === 3" :src="$store.state.icons.icons['Plumbing']" style="height: 36px" alt />
				<img v-if="value.category_id === 4" :src="$store.state.icons.icons['Masonry']" style="height: 36px" alt />
				<img v-if="value.category_id === 5" :src="$store.state.icons.icons['ToolBoxes']" style="height: 36px" alt />
				<img v-if="value.category_id === 6" :src="$store.state.icons.icons['Landscape']" style="height: 36px" alt />

				<div class="pl-7">
					<p class="heading-mf15">{{ value.list_name }}</p>
					<p class="heading-rf13">List ref: {{ value.list_id }}</p>
				</div>
			</v-col>
			<v-col cols="12" sm="6" class="d-sm-flex align-center justify-sm-end justify-start mt-sm-0 mt-4">
				<app-bid-hours v-model="value.created_at" v-if="value.status === 'AC'"></app-bid-hours>
				<app-list-hours v-model="value.idle_time" v-else></app-list-hours>
				<div v-if="value.status === 'L'" class="text-tag-btn text-tag-blue d-flex align-center justify-center mr-2">Awaiting your quote</div>
				<div v-else-if="value.status === 'E'" class="text-tag-btn text-tag-red d-flex align-center justify-center mr-2">Quote Expired</div>
				<div v-else-if="value.status === 'R'" class="text-tag-btn text-tag-red d-flex align-center justify-center mr-2">Rejected</div>
				<div v-else-if="value.status === 'AC'" class="text-tag-btn text-tag-blue d-flex align-center justify-center mr-2">Submitted</div>
			</v-col>
		</v-row>

		<div style="position: relative">
			<!-- <v-row no-gutters class="map-box">
				<v-img max-height="300" alt="Map Image" style="border-radius: 4px" lazy-src :src="getMapSrc()"></v-img>
			</v-row>
			<div class="" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)">
				<div class="map-btn" v-on:click="openMap()">
					<img :src="$store.state.icons.icons['DeliveryVan']" alt />
					Check on map
				</div>
			</div> -->
			<v-divider></v-divider>

			<div class="d-sm-flex d-block align-start py-4 justify-space-between" v-if="value.contractor && value.address">
				<div class="pb-sm-0 pb-5">
					<p class="heading-rf13 pb-sm-1 pb-0">Contact Person</p>
					<div class="heading-mf15">{{ value.contractor.fullname }}</div>
					<div class="heading-rf13">{{ phoneFormat(value.contractor.phone) }}</div>
				</div>

				<div class="heading-mf15 pb-sm-0 pb-5">
					<p class="heading-rf13 pb-sm-1 pb-0">Address</p>
					{{ value.address }}
				</div>

				<v-tooltip bottom>
					<template v-slot:activator="{ on }">
						<a class="mt-3 pb-sm-0 pb-5" v-on:click="openMapRoot(value)">
							<img :src="$store.state.icons.icons['DeliveryVan']" v-on="on" alt width="40" />
						</a>
					</template>
					<span>Click here for Map</span>
				</v-tooltip>
			</div>
		</div>
		<v-divider></v-divider>
		<App-map-view v-model="value" ref="openmap"></App-map-view>
		<App-map-view-root v-model="map_lat_long" ref="openmaproot"></App-map-view-root>
	</div>
</template>

<script>
const MapView = () => import('./MapView')
const MapViewRoot = () => import('./MapViewRoot')

export default {
	components: {
		AppMapView: MapView,
		AppMapViewRoot: MapViewRoot,
	},

	props: {
		value: Object,
	},

	data() {
		return {
			map_lat_long: {},
		}
	},

	methods: {
		phoneFormat(data) {
			if (data) {
				return data.replace(/(\d{1,3})(\d{1,3})(\d{1,4})/g, '($1)-$2-$3')
			}
		},

		getMapSrc() {
			return this.value.url != undefined
				? this.value.url['list_header']
				: 'https://the-build-static-content.s3.us-east-2.amazonaws.com/icons/map-image.png'
		},

		openMap(data) {
			// this.$refs.openmap.openMap()
			this.$refs.openmaproot.openMap(data)
		},

		openMapRoot(data) {
			this.map_lat_long = {
				category_id: data.category_id,
				list_id: data.list_id,
				list_name: data.list_name,
				list_lat: data.list_lat,
				list_long: data.list_long,
				bid_lat: data.bid_lat ? data.bid_lat : this.$store.state.auth.user_data.company.latitude,
				bid_long: data.bid_long ? data.bid_long : this.$store.state.auth.user_data.company.longitude,
				updated_at: data.list_updated_at,
				status: data.bid_status,
			}
			this.$refs.openmaproot.openMapRoot()
		},
	},
}
</script>
